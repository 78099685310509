import './bootstrap'
import '../css/app.css'
import 'floating-vue/dist/style.css';

// Import modules...
import { createApp, h } from 'vue';
import { Head, Link, createInertiaApp } from '@inertiajs/vue3'
import { resolvePageComponent } from 'laravel-vite-plugin/inertia-helpers';
import { globalFilters } from "./Shared/globalProperties";
import shadow from "vue-shadow-dom";
import LoadingLink from './Shared/Components/LoadingLink.vue';
import FormInput from './Shared/Components/FormInput.vue'
import { ZiggyVue } from '../../vendor/tightenco/ziggy/dist/vue.m';
import Notifications from '@kyvg/vue3-notification';
import FloatingVue from 'floating-vue'
import * as Sentry from "@sentry/vue";

import Vapor from './Shared/vapor';
window.Vapor = Vapor;
window.Vapor.withBaseAssetUrl(import.meta.env.VITE_VAPOR_ASSET_URL)

const appName = window.document.getElementsByTagName('title')[0]?.innerText || 'CRM';

createInertiaApp({
    title: (title) => `${title} - ${appName}`,
    resolve: (name) => resolvePageComponent(`./Pages/${name}.vue`, import.meta.glob('./Pages/**/*.vue')),
    setup({ el, App, props, plugin }) {
        const app = createApp({ render: () => h(App, props) })
            .component('InertiaHead', Head)
            .component('InertiaLink', Link)
            .component('LoadingLink', LoadingLink)
            .component('FormInput', FormInput)
            .use(plugin)
            .use(ZiggyVue, Ziggy)
            .use(FloatingVue)
            .use(Notifications)
            .use(shadow)
            .mixin({ methods: { route, asset: window.Vapor.asset } });

        app.config.globalProperties.$filter = globalFilters;

        Sentry.init({
            app,
            dsn: import.meta.env.MODE === 'development' ? '' : 'https://e79c308be57f6423c670a9d63843ad29@o4507406555480064.ingest.us.sentry.io/4507406579531776',
            environment: import.meta.env.MODE,
            tracesSampleRate: 1.0,
            trackComponents: true,
            integrations: [
                Sentry.browserTracingIntegration()
            ]
        });

        app.mount(el)
    },
    // progress: {
    //     color: "#4B5563",
    // },
})


if (import.meta.env.NODE_ENV === "production") {
    InertiaApp.on("navigate", (event) => {
        gtag('event', 'page_view', {
            'page_location': event.detail.page.url
        });
        gtag("js", new Date());
        gtag("config", "G-WSFNHH26K2");
    });
}
